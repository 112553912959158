@import 'sass/_colors.scss';
@import 'sass/_responsive.scss';

.light-section-header,
.dark-section-header {
	font-size: 1.2rem;
	font-style: italic;
	text-transform: capitalize;
	font-weight: bolder;
	color: $primary-blue;
}

.dark-section-header {
	color: $component-blue-dark-theme;
}

.light-section-content,
.dark-section-content {
	padding: 10px;
	font-size: 0.9rem;

	span {
		margin-bottom: 2px;
	}

	.sub-section {
		margin-bottom: 10px;
	}

	.light-section-border,
	.dark-section-border {
		border-top: 1px solid $light-gray-d6;
		padding: 5px 0;
		margin-top: 5px;
	}

	.dark-section-border {
		border-top: 1px solid $dark-gray;
	}

	.section-task {
		margin-left: 20px;
	}

	.section-desc {
		margin: 0 0 10px 10px;
	}

	.section-label {
		// text-transform: capitalize;
		font-weight: 600;
		padding-right: 10px;
	}

	.label-block {
		display: block;
	}

	.section-value {
		letter-spacing: 0.5px;
		font-weight: 400;
		overflow-wrap: break-word;
	}

	.section-value > ul {
		margin-top: 10px;
		list-style-position: inside;
		list-style-type: square;
	}

	.section-task > ul {
		// list-style: none;
	}

	.section-task > ul > li {
		margin-bottom: 5px;
	}

	.section-desc > li {
		margin-bottom: 5px;
	}
	.task {
		// font-style: italic;
	}

	.text-note {
		font-style: italic;
		font-size: 0.7rem;
	}
}

@include sub-section-header-media;
