/********************/
// LIGHT-THEME
$primary-blue: #05599e;
$secondary-blue: #0670c6;

$text-green: #0c6e3d;
$hover-green: #8fcc94;
$bg-green: #c7e5c9;

$primary-red: #cc2900;

$dark-hover-gray: #2b2b2b;
$dark-gray: #5d5d5d;
$footer-tab-gray: #a9a9a9;
$light-gray: #b4b4b4;
$light-hover-gray: #cccccc;
$light-gray-d6: #d6d6d6;
$bg-white: #f5f5f5;
$white: #ffffffe3;
$bright-white: #fffefe;

$rich-black-froga: #0a0a0a;
$text-color-light-theme: #141414;
$bg-loader-opacity: #1818189a;
$bg-modal-opacity: #0000005d;

//DARK-THEME
// $smoky-black: #121212;
$primary-black: #292929; //jet
$jet: #292929;
$bg-component: #424242; //onyx
// $bg-component: #474747;
$bg-dark-hover: #37383a;

$component-blue-dark-theme: #81d4fa;
