@import 'sass/_colors.scss';

.light-content-icon,
.dark-content-icon,
.disable {
	height: 35px;
	width: 35px;
	padding: 5px;
	cursor: pointer;
	border-radius: 0.2em;
	background-color: inherit;

	.light-icon,
	.dark-icon {
		width: 25px;
		height: 25px;
		color: $bg-white;
	}

	.dark-icon {
		color: $component-blue-dark-theme;
	}
}

.light-content-icon:hover {
	background-color: $secondary-blue;
	.light-icon {
		color: $bright-white;
	}
}

.dark-content-icon:hover {
	background-color: $bg-dark-hover;
	.dark-icon {
		color: $bright-white;
	}
}

.light-collapse-icons,
.dark-collapse-icons {
	position: absolute;
	background-color: $primary-blue;
	top: 51px;
	border-radius: 0.2em;
}

.dark-collapse-icons {
	background-color: $bg-component;
}

.disable {
	background-color: #cacaca7e;
	.light-icon,
	.dark-icon {
		color: $light-gray;
	}
}

.disable:hover {
	background-color: #a9a9a975;
	cursor: wait;
	.light-icon,
	.dark-icon {
		color: $light-gray;
	}
}
