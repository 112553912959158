@import 'sass/_format.scss';
@import 'sass/_colors.scss';
@import 'sass/_responsive.scss';
@import 'sass/_variables.scss';
@import './emailForm.scss';

.form-container,
.form-container-hide {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $bg-modal-opacity;
	z-index: 1;

	@include email-container-media;
}

.form-container-hide {
	position: fixed;
	width: 40px;
	height: 40px;
	background-color: transparent;
}
