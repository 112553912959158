@import 'sass/_responsive.scss';

.light-email-form,
.dark-email-form,
.light-email-form-mini,
.dark-email-form-mini {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	// right: 5px;
	// right: 10px;
	// bottom: 0.5px;
	// width: 97%;
	// width: 310px;
	// width: 400px;
	// height: calc(100% - 55px);
	// height: 500px;
	background-color: $primary-blue;
	border-radius: 0.5em 0.5em 0 0;
	box-shadow: 0 8px 10px 1px #00000024, 0 3px 14px 2px #0000001f,
		0 5px 5px -3px #00000033;
	z-index: 1;

	.light-email-form-header,
	.dark-email-form-header {
		width: 100%;
		height: 40px;
		background-color: $primary-blue;
		border-radius: inherit;
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;

		.email-header-subject {
			width: 100%;
			color: $white;
			margin-left: 10px;
			cursor: default;
		}

		.light-mini-bottom,
		.dark-mini-bottom,
		.light-mini-top,
		.dark-mini-top,
		.light-mini-screen,
		.dark-mini-screen {
			width: 24px;
			height: 24px;
			position: absolute;
			right: 24px;
			margin-right: 5px;
			border-radius: 0.2em;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			cursor: pointer;
			color: $bg-white;

			.mini-bottom-icon,
			.mini-screen-icon {
				width: 18px;
				height: 18px;
			}

			&:hover {
				background-color: $secondary-blue;
				.mini-bottom-icon,
				.mini-screen-icon {
					color: $bright-white;
				}
			}
		}

		.light-mini-bottom,
		.dark-mini-bottom,
		.light-mini-top,
		.dark-mini-top {
			right: 48px;
		}

		.light-mini-top,
		.dark-mini-top {
			align-items: flex-start;
		}

		.light-mini-screen,
		.dark-mini-screen {
			align-items: center;
		}

		.dark-mini-bottom,
		.dark-mini-top,
		.dark-mini-screen {
			color: $component-blue-dark-theme;

			&:hover {
				// background-color: $bg-dark-hover;
				background-color: $dark-gray;
				color: $bright-white;
			}
		}
	}

	.dark-email-form-header {
		background-color: $bg-dark-hover;
	}

	.light-email-content,
	.dark-email-content {
		width: 100%;
		height: calc(100% - 40px);
		background-color: $bg-white;
		padding: 0 15px;

		.email-content-header {
			display: flex;
			flex-direction: column;

			.email-content-header-info {
				width: 100%;
				height: 40px;
				padding: 10px 0;
				box-shadow: inset 0 -1px 0 0 #64798f1f;
				line-height: 20px;
				color: $dark-gray;
				display: flex;
				justify-content: center;
				align-items: center;

				label,
				input {
					font-size: 0.9rem;
				}

				input {
					width: 100%;
					height: calc(40px - 10px);
					margin-left: 5px;
					border: none;
					background-color: transparent;
					outline: none;
				}

				.email-subject {
					margin-left: 0;
				}

				::placeholder {
					/* Chrome, Firefox, Opera, Safari 10.1+ */
					color: $dark-gray;
					opacity: 1; /* Firefox */
				}

				:-ms-input-placeholder {
					/* Internet Explorer 10-11 */
					color: $dark-gray;
				}

				::-ms-input-placeholder {
					/* Microsoft Edge */
					color: $dark-gray;
				}
			}
		}
		.email-textarea {
			margin: 10px 0;
			width: 100%;
			// height: calc(460px - 230px);
			background-color: transparent;
			border: none;
			outline: none;
			resize: none;
			font-family: inherit;
		}

		.email-form-footer {
			width: 100%;
			height: 40px;
			background-color: transparent;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.btn-send-email {
				width: 80px;
				height: 35px;
				border: 0.5px solid $primary-blue;
				border-radius: 0.2em;
				background-color: transparent;
				outline: none;
				color: $primary-blue;
				font-size: 1rem;
				font-weight: 600;

				&:hover {
					background-color: $primary-blue;
					border-color: $secondary-blue;
					color: $bg-white;
				}
			}

			.discard-icon {
				width: 18px;
				height: 18px;
				color: $dark-gray;

				&:hover {
					color: $primary-red;
				}
			}
		}
	}

	.dark-email-content {
		background-color: $light-gray-d6;
	}

	.email-content-none {
		display: none;
	}
}

.light-email-form-relative,
.dark-email-form-relative {
	position: initial;
	z-index: 2;
}

.light-email-form-mini,
.dark-email-form-mini {
	position: fixed;
	width: 240px;
	height: 40px;
	bottom: 0.5px;
	right: 10px;
	top: auto;
	left: auto;
}

.dark-email-form,
.dark-email-form-mini,
.dark-email-form-relative {
	background-color: $bg-dark-hover;
}

@include email-container-media;
