@import 'sass/_colors.scss';
@import 'sass/_anim.scss';

.bar-loader-container {
	width: 100%;
	height: 1px;
	background-color: $bg-white;
	position: absolute;
	// bottom: 14px;
	bottom: 1px;
	// top: 1px;
	left: 0;
	overflow: hidden;

	.bar-loader-progress {
		position: absolute;
		width: 30%;
		height: 1px;
		background-color: $secondary-blue;
		border-radius: 0.2em;
		transform: translateX(-95%);
		animation: progress-lft-rgt 0.5s infinite alternate ease-in-out;

		@include progress-lft-rgt;
	}
}
