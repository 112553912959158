@use 'sass/_colors.scss';

.logo-container {
	position: relative;
	width: 120px;
	height: 120px;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: all 2s ease-out;

	.loader-center {
		position: absolute;
		margin: 0;
		width: 68px;
		height: 66px;
		background-color: $secondary-blue;
		border-radius: 0.2em;

		.logo-icon {
			width: 68px;
			height: 68px;
			g {
				fill: $bg-white;
			}
		}
	}

	.logo-label {
		position: absolute;
		top: 40px;
		left: 25px;
		font-size: 2.5rem;
		color: $bg-white;
		font-weight: bold;
		font-family: monospace;
	}

	.circle-container {
		width: 120px;
		height: 120px;
		position: absolute;
		top: 0;
		left: 0;
		border: 2px solid transparent;
		border-top-color: #1768aa;
		border-right-color: #1768aae0;
		border-bottom-color: #1768aa85;
		border-left-color: #1768aa14;
		border-radius: 50%;
		animation: circle-spinner-animation 670ms linear infinite 0.5s;
		z-index: 1;
	}

	@keyframes circle-spinner-animation {
		100% {
			transform: rotate(360deg);
		}
	}
}
