@import 'sass/_colors.scss';
@import 'sass/_variables.scss';
@import 'sass/_format.scss';
@import 'sass/_responsive.scss';

.main-container {
	width: 314px;
	position: relative;
	margin: 0 auto;

	.grid-container {
		display: grid;
		grid-auto-rows: auto;
		grid-gap: 10px 10px;
		padding: 10px 0;
		background-color: transparent;

		.light-grid-item,
		.dark-grid-item {
			background-color: $white;
			border-radius: 0.2em;
			padding: 10px;
			color: $text-color-light-theme;
			@include light-section-shadow;
		}

		.dark-grid-item {
			background-color: $bg-component;
			color: $bg-white;
			// @include dark-section-shadow;
			// transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
			box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
				0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
		}
	}
}

@include content-media;
