@import '../../sass/_colors.scss';
@import './polar.scss';
@import './logoLoader.scss';
@import './barLoader.scss';

.bg-loader,
.bg-loader-opc {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	min-height: 100vh;
	pointer-events: none;
	cursor: none;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 2;
}

.bg-loader-opc {
	background-color: $bg-loader-opacity;
}
