@import 'sass/_colors.scss';
@import 'sass/_responsive.scss';

//Copy Link Success
.cp-alert-container {
	position: absolute;
	left: 0;
	width: 100%;
	height: 45px;
	display: flex;
	justify-content: flex-end;
	top: 0px;

	.cp-success {
		position: relative;
		width: 100%;
		height: 100%;
		background-color: $bg-green;
		color: $text-green;
		display: flex;
		align-items: center;
		font-size: 1.2rem;
		animation: animflowdown 2.5s;
		animation-fill-mode: forwards;

		.cp-alert-msg {
			width: 100%;
			text-align: center;
		}
	}
}

//Messages Sent Success
.email-alert-container {
	position: fixed;
	width: 100%;
	height: 45px;
	display: flex;
	justify-content: center;
	bottom: 0px;
	z-index: 1;

	.email-success {
		position: relative;
		width: 100%;
		min-width: 275px;
		max-width: 400px;
		height: 100%;
		background-color: $bg-green;
		color: $text-green;
		display: flex;
		align-items: center;
		font-size: 1.2rem;
		border-radius: 0.2em;
		border: 1px solid $text-green;
		animation: animflowup 2.5s;
		animation-fill-mode: forwards;

		.email-alert-msg {
			text-align: center;
			width: 100%;
		}
	}
}

//Error Modal
.error-alert-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: $footer-tab-gray;
	background-image: url('/assets/unsplash-notebook.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	overflow: hidden;
	opacity: 0;
	animation: fade-in 0.5s ease-in forwards;
	animation-delay: 0.5s;
	z-index: 1;

	.notfound-icon {
		width: 70%;
	}

	.error-msg {
		width: 80%;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

		.error-msg-title,
		.error-msg-content {
			width: 100%;
			font-family: 'Courier New', Courier, monospace;
			text-align: center;
		}

		.error-msg-title {
			margin: 5px 0 20px 0;
			font-size: 1.5rem;
			font-weight: 700;
		}

		.error-msg-content {
			font-size: 1rem;
			font-weight: 600;
			line-height: 20px;

			.btn-home {
				font: inherit;
				width: 90px;
				height: 25px;
				background-color: transparent;
			}
		}
	}
}

@include pagenotfound-media;

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes animflowdown {
	0% {
		top: -45px;
	}
	50% {
		top: 0;
	}
	100% {
		top: -45px;
	}
}

@keyframes animflowup {
	0% {
		bottom: -45px;
	}
	50% {
		bottom: 1px;
	}
	100% {
		bottom: -45px;
	}
}
