@mixin ul-li-reset {
	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	li {
		display: block;
		list-style-type: none;
	}
}

@mixin light-section-shadow {
	box-shadow: 0 0.5px 0 1px $light-gray;
}

@mixin dark-section-shadow {
	box-shadow: 0 0.5px 0 1px $dark-gray;
}
