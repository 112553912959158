@import './sass/_colors.scss';
@import './sass/_format.scss';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	// width: 100%;
	// height: 100%;
	// overflow: hidden;
}

:root {
	font-size: 16px;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	overflow-y: auto;
	// @include ul-li-reset;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
