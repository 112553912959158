@import 'sass/_colors.scss';
@import 'sass/_variables.scss';
@import 'sass/_responsive.scss';

.light-nav,
.dark-nav {
	width: 100%;
	height: 110px;
	background-color: $primary-blue;
	color: $bg-white;
	display: flex;
	flex-direction: column;
	align-items: center;

	.main-header {
		width: 100%;
		height: 55px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;

		.light-logo,
		.dark-logo {
			margin-left: 10px;
			width: 60px;
			height: 41px;
			background-color: transparent;

			.logo-icon {
				width: 60px;
				height: 40px;
				color: $bg-white;
				g {
					fill: $bg-white;
					stroke-width: 80;
					stroke-linecap: square;
				}
			}
		}

		.user-name {
			font-size: 1.2rem;
			font-weight: 600;
		}

		.user-avatar {
			border-radius: 50%;
			height: 50px;
			width: 50px;
			margin-right: 5px;
			display: flex;
			align-items: center;
			padding: 5px;
		}

		.avatar-container {
			width: 40px;
			height: 40px;
			border-radius: 50%;
			background-color: $light-gray;
			border: none;
		}

		.avatar-img {
			width: 40px;
			height: 40px;
			padding: 0;
			border-radius: 50%;
		}

		.dark-logo {
			.logo-icon {
				g {
					fill: $component-blue-dark-theme;
				}
			}
		}
	}

	.collapse-menu-container {
		display: flex;
		width: 200px;
		justify-content: center;
		align-items: center;
		margin-top: 10px;

		.light-menu-icons,
		.dark-menu-icons {
			display: flex;
			width: 100%;
			justify-content: space-around;
		}
	}

	.setting-menu-container {
		display: none;
	}

	@include menu-media;
}
.dark-nav {
	background-color: $bg-component;
}

@include nav-media;
