@import 'sass/_colors.scss';
@import 'sass/_variables.scss';
@import 'sass/_responsive.scss';

.app{
	background-color: $white;
}
.light-App,
.dark-App {
	background-color: $light-gray-d6;
	width: 100vw;
	min-height: 100vh;
	opacity: 0;
	animation: fade-in 0.5s ease-in forwards;
	animation-delay: 0.5s;
}

.dark-App {
	background-color: $primary-black;
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@include main-media;
